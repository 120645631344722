import React from 'react';
import { Col, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { API, graphqlOperation } from 'aws-amplify';

import { Text, Toolbar } from './styles';
import { formatDate, formatLastSeen } from '../utils';
import * as mutations from '../graphql/mutations';

export default class extends React.Component {
  state = this.initState();

  initState(props = this.props) {
    const newUser = {
      Username: '',
      UserAttributes: [],
    };
    return props.user || newUser;
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleAttributeChange = (name, e) => {
    const value = e.target ? e.target.value : e;

    const attributes = this.state.UserAttributes.filter(
      ({ Name }) => Name !== name
    );
    const UserAttributes = [...attributes, { Name: name, Value: value }];

    this.setState({
      UserAttributes,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { user, userAttributes } = this.props;
    const {
      Username,
      UserAttributes: CognitoUserAttributes,
      LastSeen,
    } = this.state;
    const isUpdate = !!user;
    const includes = userAttributes.map((attr) => attr.name);
    const included = CognitoUserAttributes.filter(({ Name }) =>
      includes.includes(Name)
    );
    const UserAttributes = [
      ...included.map(({ Name, Value }) => ({
        Name,
        Value: Value.value || Value,
      })),
      { Name: 'email_verified', Value: true },
    ];
    const mutation = isUpdate ? mutations.updateUser : mutations.createUser;

    try {
      const { data } = await API.graphql(
        graphqlOperation(mutation, {
          username: Username,
          attributes: UserAttributes,
        })
      );

      const { user, error } = data.data || {};

      if (error) {
        this.setState({ error: error.message }, this.setAlertTimer);
      } else {
        this.setState({ ...user, LastSeen, success: true }, this.setAlertTimer);
        this.props.onSubmit({ Username });
      }
    } catch ({ errors = [] }) {
      const [error = {}] = errors;
      this.setState({ error: error.message }, this.setAlertTimer);
    }
  };

  toggleEnabled = async () => {
    const { Username, Enabled } = this.state;
    const mutation = Enabled ? mutations.disableUser : mutations.enableUser;

    const { data } = await API.graphql(
      graphqlOperation(mutation, { username: Username })
    );

    this.setState(data.user);
  };

  deleteUser = async () => {
    const { Username } = this.state;

    await API.graphql(
      graphqlOperation(mutations.deleteUser, { username: Username })
    );

    this.props.onDelete();
  };

  clearAlertState = () => {
    this.setState({ success: undefined, error: undefined });
  };

  setAlertTimer = () => {
    this.alertTimer = setTimeout(this.clearAlertState, 2000);
  };

  componentWillUnmount() {
    clearTimeout(this.alertTimer);
  }

  getAttributeValue = (name) => {
    const { userAttributes = [] } = this.props;
    const { UserAttributes = [] } = this.state;
    const attr = userAttributes.find((attr) => attr.name === name);
    const UserAttribute =
      UserAttributes.find((attr) => attr.Name === name) || {};

    return attr.props && attr.props.options
      ? attr.props.options.find(
          (option) => option.value === UserAttribute.Value
        )
      : UserAttribute.Value || '';
  };

  render() {
    const { user, userAttributes = [] } = this.props;
    const isUpdate = !!user;

    return (
      <div>
        {isUpdate && (
          <Toolbar>
            <Button outline color="primary" onClick={this.toggleEnabled}>
              {this.state.Enabled ? 'Disable user' : 'Enable user'}
            </Button>
            {!this.state.Enabled && (
              <Button outline color="danger" onClick={this.deleteUser}>
                Delete User
              </Button>
            )}
          </Toolbar>
        )}
        <Form onSubmit={this.handleSubmit}>
          {this.state.success && (
            <Alert color="success">User successfully saved!</Alert>
          )}
          {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
          <FormGroup row>
            <Label for="Username" sm={2}>
              Username
            </Label>
            <Col sm={10}>
              <Input
                value={this.state.Username}
                onChange={this.handleChange}
                disabled={isUpdate}
                type="text"
                name="Username"
              />
            </Col>
          </FormGroup>
          {isUpdate && (
            <>
              <FormGroup row>
                <Label for="CreatedAt" sm={2}>
                  CreatedAt
                </Label>
                <Col sm={10}>
                  <Text>{formatDate(this.state.UserCreateDate)}</Text>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="UpdatedAt" sm={2}>
                  UpdatedAt
                </Label>
                <Col sm={10}>
                  <Text>{formatDate(this.state.UserLastModifiedDate)}</Text>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Status" sm={2}>
                  Status
                </Label>
                <Col sm={10}>
                  <Text>{this.state.UserStatus}</Text>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Status" sm={2}>
                  Enabled
                </Label>
                <Col sm={10}>
                  <Text>{String(this.state.Enabled)}</Text>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Status" sm={2}>
                  LastSeen
                </Label>
                <Col sm={10}>
                  <Text>{formatLastSeen(this.state.LastSeen)}</Text>
                </Col>
              </FormGroup>
            </>
          )}
          {/* {!isUpdate && (
            <FormGroup row>
              <Label for="TemporaryPassword" sm={2}>
                Temporary password
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.handleChange}
                  type="password"
                  name="TemporaryPassword"
                  autoComplete="off"
                />
              </Col>
            </FormGroup>
          )} */}
          <hr />
          {userAttributes.map((attr) => {
            const { name, label, Component = Input, props } = attr;

            return (
              <FormGroup row key={name}>
                <Label for={name} sm={2}>
                  {label}
                </Label>
                <Col sm={10}>
                  <Component
                    value={this.getAttributeValue(name)}
                    onChange={this.handleAttributeChange.bind(this, name)}
                    id={name}
                    name={name}
                    {...props}
                  />
                </Col>
              </FormGroup>
            );
          })}
          <hr />
          <FormGroup row>
            <Col sm={10}>
              <Button>{isUpdate ? 'Update user' : 'Create user'}</Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
