import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input, Table } from 'reactstrap';

const noop = () => { };

export default class EnableCharts extends React.Component {
  static propTypes = {
    enableCharts: {
      chartTransportOverview: PropTypes.bool,
      chartInternationalTransports: PropTypes.bool,
      chartDeliveryTimeReportByCountryCargo: PropTypes.bool,
      chartNegativeReportByCountryCargo: PropTypes.bool,
      chartDeliveryTimeReportByCountryPackages: PropTypes.bool,
      chartNegativeReportByCountryPackages: PropTypes.bool,
      chartTransportsDevelopment: PropTypes.bool,
      chartDeliveriesByCountry: PropTypes.bool,
      chartStoragePerformanceToday: PropTypes.bool,
      chartStoragePerformanceSpreitenbach: PropTypes.bool,
      chartStoragePerformanceBasel: PropTypes.bool,
      chartStoragePerformanceSchaffhausen: PropTypes.bool,
      chartStoragePerformanceBuchs: PropTypes.bool,
    },
    onEnableChartsUpdate: PropTypes.func,
    aclKey: PropTypes.string.isRequired
  };
  static defaultProps = {
    enableCharts: {
      chartTransportOverview: true,
      chartInternationalTransports: true,
      chartDeliveryTimeReportByCountryCargo: true,
      chartNegativeReportByCountryCargo: true,
      chartDeliveryTimeReportByCountryPackages: true,
      chartNegativeReportByCountryPackages: true,
      chartTransportsDevelopment: true,
      chartDeliveriesByCountry: true,
      chartStoragePerformanceToday: true,
      chartStoragePerformanceSpreitenbach: true,
      chartStoragePerformanceBasel: true,
      chartStoragePerformanceSchaffhausen: true,
      chartStoragePerformanceBuchs: true,
    },
    onEnableChartsUpdate: noop,
    aclKey: ''
  };

  constructor(props) {
    super(props);
  }

  valueOrTrue(value) { return value === undefined || value === null ? true : value }

  render() {
    const { enableCharts } = this.props;
    return (
      <FormGroup row key={"enableCharts-" + this.props.aclKey} style={{ 'clear': 'both' }}>
        <Label sm={4}>
          Charts enabled
        </Label>
        <Col sm={8}>
          <Table borderless>
            <tbody>
              <tr>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartTransportOverview"} name={"enableCharts-" + this.props.aclKey + "-chartTransportOverview"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartTransportOverview')} checked={this.valueOrTrue(enableCharts.chartTransportOverview)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartTransportOverview"} >Transport overview</label></td>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartInternationalTransports"} name={"enableCharts-" + this.props.aclKey + "-chartInternationalTransports"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartInternationalTransports')} checked={this.valueOrTrue(enableCharts.chartInternationalTransports)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartInternationalTransports"} >International transports</label></td>
              </tr><tr>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartDeliveryTimeReportByCountryCargo"} name={"enableCharts-" + this.props.aclKey + "-chartDeliveryTimeReportByCountryCargo"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartDeliveryTimeReportByCountryCargo')} checked={this.valueOrTrue(enableCharts.chartDeliveryTimeReportByCountryCargo)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartDeliveryTimeReportByCountryCargo"} >Delivery time report by country (cargo)</label></td>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartNegativeReportByCountryCargo"} name={"enableCharts-" + this.props.aclKey + "-chartNegativeReportByCountryCargo"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartNegativeReportByCountryCargo')} checked={this.valueOrTrue(enableCharts.chartNegativeReportByCountryCargo)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartNegativeReportByCountryCargo"} >Negative report by country (cargo)</label></td>
              </tr><tr>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartDeliveryTimeReportByCountryPackages"} name={"enableCharts-" + this.props.aclKey + "-chartDeliveryTimeReportByCountryPackages"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartDeliveryTimeReportByCountryPackages')} checked={this.valueOrTrue(enableCharts.chartDeliveryTimeReportByCountryPackages)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartDeliveryTimeReportByCountryPackages"} >Delivery time report by country (packages)</label></td>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartNegativeReportByCountryPackages"} name={"enableCharts-" + this.props.aclKey + "-chartNegativeReportByCountryPackages"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartNegativeReportByCountryPackages')} checked={this.valueOrTrue(enableCharts.chartNegativeReportByCountryPackages)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartNegativeReportByCountryPackages"} >Negative report by country (packages)</label></td>
              </tr><tr>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartTransportsDevelopment"} name={"enableCharts-" + this.props.aclKey + "-chartTransportsDevelopment"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartTransportsDevelopment')} checked={this.valueOrTrue(enableCharts.chartTransportsDevelopment)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartTransportsDevelopment"} >Transports development</label></td>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartDeliveriesByCountry"} name={"enableCharts-" + this.props.aclKey + "-chartDeliveriesByCountry"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartDeliveriesByCountry')} checked={this.valueOrTrue(enableCharts.chartDeliveriesByCountry)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartDeliveriesByCountry"} >Deliveries by country</label></td>
              </tr><tr>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceToday"} name={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceToday"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartStoragePerformanceToday')} checked={this.valueOrTrue(enableCharts.chartStoragePerformanceToday)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceToday"} >Storage performance today</label></td>
              </tr><tr>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceSpreitenbach"} name={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceSpreitenbach"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartStoragePerformanceSpreitenbach')} checked={this.valueOrTrue(enableCharts.chartStoragePerformanceSpreitenbach)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceSpreitenbach"} >Storage performance Spreitenbach</label></td>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceBasel"} name={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceBasel"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartStoragePerformanceBasel')} checked={this.valueOrTrue(enableCharts.chartStoragePerformanceBasel)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceBasel"} >Storage performance Basel</label></td>
              </tr><tr>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceSchaffhausen"} name={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceSchaffhausen"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartStoragePerformanceSchaffhausen')} checked={this.valueOrTrue(enableCharts.chartStoragePerformanceSchaffhausen)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceSchaffhausen"} >Storage performance Schaffhausen</label></td>
                <td><Input type="checkbox" id={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceBuchs"} name={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceBuchs"} onChange={(e) => this.props.onEnableChartsUpdate(e, 'chartStoragePerformanceBuchs')} checked={this.valueOrTrue(enableCharts.chartStoragePerformanceBuchs)} style={{ 'margin-left': '0' }} /></td>
                <td><label htmlFor={"enableCharts-" + this.props.aclKey + "-chartStoragePerformanceBuchs"} >Storage performance Buchs</label></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </FormGroup>
    );
  }
}
